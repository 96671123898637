import { MenuRoute } from '@blb-ventures/react-components';
import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';
import { StyledBorderedButton } from '@web/common/styles/admin';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const MenuWhiteItemText = styled(Typography)<{ $active?: boolean }>`
  margin-top: ${props => props.theme.spacing(0.5)};
  color: ${props => (props.$active ? '#FFF' : undefined)};
  transition: color 0.4s ease-in-out;
`;

const StyledGradientPaper = styled(Paper)<{ $active?: boolean }>`
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: transparent;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    background: ${props => props.theme.palette.primary.main};
    transition: opacity 0.4s ease-in-out;
    opacity: ${props => (props.$active ? 1 : 0)};
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    background: white;
  }
  path {
    fill: ${props => (props.$active ? '#FFF !important' : '#777')};
    transition: fill 0.4s ease-in-out;
  }
`;

export const ButtonNavigationItem: FC<MenuRoute & { className?: string }> = ({
  icon,
  label,
  url,
  active,
  onClick,
  className,
}) => {
  return (
    <StyledBorderedButton
      {...{
        component: url != null ? Link : undefined,
        to: typeof url === 'string' ? url : url?.pathname ?? '',
      }}
      onClick={onClick}
      className={className}
    >
      <StyledGradientPaper $active={active}>
        {icon}
        <MenuWhiteItemText variant="body2" $active={active}>
          {label}
        </MenuWhiteItemText>
      </StyledGradientPaper>
    </StyledBorderedButton>
  );
};
