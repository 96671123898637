import { ProductCoffeeKind, SubscriptionKind } from 'core/lib/graphql/types.d';
import { SubscriptionPlanFieldsFragment } from 'core/lib/graphql/_gen/plans';
import { useMemo } from 'react';

export const useSupportedPlans = (
  plans: SubscriptionPlanFieldsFragment[],
  currentCoffeeKind: ProductCoffeeKind,
) => {
  const supportedPlans: SubscriptionKind[] | [] = useMemo(() => {
    const filteredPlans = plans.reduce((acc: any, plan) => {
      if (
        !acc.includes(plan.kind) &&
        plan.kind !== SubscriptionKind.Free &&
        plan.kind !== SubscriptionKind.Biannual &&
        plan.coffeeKind === currentCoffeeKind
      ) {
        return [...acc, plan.kind];
      }
      return acc;
    }, []);
    return Array.from(new Set([SubscriptionKind.Monthly, ...filteredPlans]));
  }, [currentCoffeeKind, plans]);
  return { supportedPlans };
};
