export const formatPhone = (value: string | number) => {
  const phoneNumber =
    typeof value === 'string' ? parseInt(value.replace(/[^0-9]/gi, ''), 10) : value;
  if (typeof phoneNumber === 'number') {
    const phone = phoneNumber.toString();
    if (phone.length === 13) {
      return `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 9)}-${phone.slice(
        9,
        13,
      )}`;
    }
    if (phone.length === 12) {
      return `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 8)}-${phone.slice(
        8,
        12,
      )}`;
    }
    if (phone.length === 11) {
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7, 11)}`;
    }
    if (phone.length === 10) {
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6, 10)}`;
    }
    if (phone.length === 9) {
      return `${phone.slice(0, 5)}-${phone.slice(5, 9)}`;
    }
    if (phone.length === 8) {
      return `${phone.slice(0, 4)}-${phone.slice(4, 8)}`;
    }
  }
  return '';
};
