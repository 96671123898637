import styled from '@emotion/styled';
import { Badge, ButtonProps, Typography } from '@mui/material';
import { StyledBorderedButton } from '@web/common/styles/admin';
import { FC, ReactNode } from 'react';

const Text = styled(Typography)<{ $active?: boolean }>`
  color: ${props => (props.$active ? '#FFF' : undefined)};
`;

const Tab = styled.div<{ $active?: boolean; $customBorderRadius?: number }>`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props =>
    props.$customBorderRadius ? `${props.$customBorderRadius}px` : 'inherit'};
  background: ${props => (props.$active ? props.theme.palette.primary.main : 'white')};
`;

const SmartTabBadge = styled(Badge)`
  width: 100%;
  flex-shrink: 1;
  & .MuiBadge-badge {
    padding: ${props => props.theme.spacing(1.5)};
    transform: scale(1) translate(0%, -50%);
  }
`;

interface SmartTabProps extends ButtonProps {
  active: boolean;
  customBorderRadius?: number;
  badgeContent?: ReactNode;
}

export const SmartTab: FC<SmartTabProps> = ({
  children,
  active,
  onClick,
  customBorderRadius,
  badgeContent,
  ...rest
}) => (
  <SmartTabBadge
    badgeContent={badgeContent}
    color="success"
    invisible={badgeContent == null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <StyledBorderedButton onClick={onClick} {...rest}>
      <Tab $active={active} $customBorderRadius={customBorderRadius}>
        <Text $active={active}>{children}</Text>
      </Tab>
    </StyledBorderedButton>
  </SmartTabBadge>
);
