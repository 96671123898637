import { Typography } from '@mui/material';
import { SmartTabs, SmartTabsProps } from '@web/admin/components/smart-tabs';
import { SubscriptionKind } from 'core/lib/graphql/types.d';
import { FC, MouseEvent, PropsWithChildren, useMemo } from 'react';
import styled from '@emotion/styled';

type MenuSmartTabs = (props: PropsWithChildren<SmartTabsProps<SubscriptionKind>>) => JSX.Element;

const MenuSmartTabsPlans = styled(SmartTabs as MenuSmartTabs)`
  height: 64px;
  button > div {
    padding: ${props => props.theme.spacing(3)};
  }
`;

const plansKind: Record<SubscriptionKind, string> = {
  [SubscriptionKind.Monthly]: 'Mensal',
  [SubscriptionKind.Biannual]: 'Semestral',
  [SubscriptionKind.Yearly]: 'Anual',
  [SubscriptionKind.Free]: 'Grátis',
  [SubscriptionKind.CoffeePass]: 'CoffeePass',
};

const plansSortedByKind: Record<SubscriptionKind, number> = {
  [SubscriptionKind.Yearly]: 1,
  [SubscriptionKind.Biannual]: 2,
  [SubscriptionKind.Monthly]: 3,
  [SubscriptionKind.Free]: 4,
  [SubscriptionKind.CoffeePass]: 5,
};

export interface PlansKindProps {
  currentPlanKind: SubscriptionKind;
  supportedPlans: { kind: SubscriptionKind; discount: number }[];
  onPlanKindChange: (e: MouseEvent<Element, globalThis.MouseEvent>, kind: SubscriptionKind) => void;
}

export const PlansKind: FC<PlansKindProps> = ({
  currentPlanKind,
  supportedPlans,
  onPlanKindChange,
}) => {
  const sortedPlansKind = useMemo(
    () => supportedPlans.sort((a, b) => plansSortedByKind[a.kind] - plansSortedByKind[b.kind]),
    [supportedPlans],
  );
  const tabs = useMemo(
    () =>
      sortedPlansKind.map(item => ({
        label: plansKind[item.kind],
        badgeContent:
          item.discount > 0 ? (
            <Typography variant="caption">{`Até ${item.discount * 100}% OFF`}</Typography>
          ) : null,
        value: item.kind,
      })),
    [sortedPlansKind],
  );
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary" align="center" sx={{ marginBottom: 1 }}>
        Selecione o tipo de plano
      </Typography>
      <MenuSmartTabsPlans
        tabs={tabs}
        customBorderRadius={10}
        currentTab={currentPlanKind}
        onClick={onPlanKindChange}
      />
    </>
  );
};
