import styled from '@emotion/styled';
import { Box, Divider, ThemeProvider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridFlatList } from '@web/common/components/grid-flat-list';
import { formatCurrency } from '@web/common/formatters';
import { licor43Theme } from '@web/common/licor43Theme';
import { decodeId } from '@web/common/relay';
import { SmartImage } from '@web/common/smart-image';
import { PlanContainer } from '@web/common/styles/plans';
import { theme as verooTheme } from '@web/common/theme';
import { SubscriptionKind } from 'core/lib/graphql/types.d';
import { SubscriptionPlanFieldsFragment } from 'core/lib/graphql/_gen/plans';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, Fragment, MouseEvent, useMemo } from 'react';
import { ButtonPlan } from './button-plan';
import { CustomPlan } from './customized-plan';
import { LinkWrapper } from './link-wrapper';
import { subscriptionPlanLabelDict } from './plans-type';

export interface PlansSectionProps {
  plans: SubscriptionPlanFieldsFragment[];
  customizedPlanId: string;
  fullWidth?: boolean;
  onPlanClick?: (e: MouseEvent) => void;
  currentPlanKind: SubscriptionKind;
}

export const ItemImage = styled(SmartImage)`
  img {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    object-position: center;
    border-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;

const PlanContainerMenu = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 ${props => props.theme.spacing(-2)};
  & > div,
  & > a {
    flex-basis: calc(25% - ${props => props.theme.spacing(2)});
  }
`;

export const ItemDivider = styled(Divider)`
  margin: ${props => props.theme.spacing(2, 0)};
`;

export const PlansSection: FC<PlansSectionProps> = ({
  plans,
  fullWidth = false,
  customizedPlanId,
  currentPlanKind,
  onPlanClick,
}) => {
  const { query } = useRouter();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const PlansWrapper = useMemo(() => (mdUp ? PlanContainerMenu : GridFlatList), [mdUp]);
  return (
    <PlansWrapper className="plans-wrapper" horizontalBreakpoint="sm">
      {plans.map(plan => (
        <Fragment key={plan.id}>
          <ThemeProvider theme={plan.name.includes('43') ? licor43Theme : verooTheme}>
            <PlanContainer
              className={plan.kind}
              bgColor={plan.name.includes('43') ? 'dark' : 'default'}
              fullWidth={fullWidth}
            >
              <ItemImage
                image={plan.image ?? { url: '/icons/graos-selecionados.svg' }}
                lazy
                lazyProps={{ height: 200, offset: 50 }}
                skipResolutionSizes={plan.image == null}
              />
              <Box mt={2}>
                <Typography variant="h6" component="h3" color="textPrimary">
                  {plan.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {plan.description}
                </Typography>
              </Box>
              <ItemDivider />
              <Box mb={2}>
                <Typography variant="body2" color="textSecondary">
                  A partir de
                </Typography>
                {plan.kind === SubscriptionKind.Yearly && (
                  <Typography variant="body1" color="textSecondary" component="span">
                    12x{' '}
                  </Typography>
                )}
                <Typography variant="h4" color="textPrimary" component="span">
                  {`${formatCurrency(plan.minimumPrice ?? 0)} /mês`}
                </Typography>
              </Box>
              {plan.cta ? (
                <LinkWrapper ctaAction={plan.cta}>
                  <ButtonPlan kind={plan.kind} buttonLabel="Mais detalhes" />
                </LinkWrapper>
              ) : (
                <NextLink
                  href={{ pathname: `/loja/carrinho-assinatura/${decodeId(plan.id).id}`, query }}
                  passHref
                >
                  <ButtonPlan
                    kind={plan.kind}
                    buttonLabel={`Assine ${subscriptionPlanLabelDict[plan.kind]}`}
                    onClick={onPlanClick}
                  />
                </NextLink>
              )}
            </PlanContainer>
          </ThemeProvider>
        </Fragment>
      ))}
      <CustomPlan onClick={onPlanClick} planId={customizedPlanId} planKind={currentPlanKind} />
    </PlansWrapper>
  );
};
