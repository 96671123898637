const defaultOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'BRL',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export const formatCurrency = (
  value: number | string,
  options?: Intl.NumberFormatOptions,
): string => {
  const num = typeof value === 'string' ? parseFloat(value) : value;
  return Intl.NumberFormat('pt-BR', { ...options, ...defaultOptions }).format(num);
};
