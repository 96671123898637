import { createTheme } from '@mui/material';
import { theme } from '@web/common/theme';

export const licor43Theme = createTheme({
  ...theme,
  palette: {
    primary: {
      main: '#262626',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFBF00',
      contrastText: '#000',
    },
    success: {
      main: '#35821C',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FF1053',
      contrastText: '#FFF',
    },
    background: {
      default: '#000',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.8)',
    },
  },
  components: {
    ...theme.components,
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
      },
    },
  },
});
