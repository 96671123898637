import { Button, ButtonProps } from '@mui/material';
import { SubscriptionKind } from 'core/lib/graphql/types.d';
import { FC } from 'react';

interface ButtonPlanProps extends ButtonProps {
  kind: SubscriptionKind;
  buttonLabel: string;
  href?: string;
}

export const ButtonPlan: FC<ButtonPlanProps> = ({ kind, buttonLabel, href, ...props }) => (
  <Button color="primary" variant="contained" size="large" fullWidth href={href} {...props}>
    {buttonLabel}
  </Button>
);
