import { Link } from '@mui/material';
import { VEROO_ORIGIN } from '@web/common/constants/url';
import { Maybe } from 'graphql/jsutils/Maybe';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

interface LinkWrapperProps {
  ctaAction?: Maybe<string>;
}

export const LinkWrapper: FC<LinkWrapperProps> = ({ children, ctaAction }) => {
  const { query } = useRouter();

  if (ctaAction == null) {
    return <div>{children}</div>;
  }

  const url = new URL(ctaAction);
  Object.entries(query).forEach(([k, v]) => {
    if (typeof v === 'string') {
      url.searchParams.append(k, v);
    }
  });
  const isSameOrigin = url.origin === VEROO_ORIGIN;
  return (
    <NextLink href={url} passHref>
      <Link
        underline="none"
        href={url.href}
        target={!isSameOrigin ? '_blank' : undefined}
        rel="noreferrer"
      >
        {children}
      </Link>
    </NextLink>
  );
};
