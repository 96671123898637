import { Paper } from '@mui/material';
import styled from '@emotion/styled';

export const PlanContainer = styled(Paper)<{ bgColor: 'default' | 'dark'; fullWidth?: boolean }>`
  text-align: center;
  padding: ${props => props.theme.spacing(2)};
  margin: ${props => props.theme.spacing(1)};
  background-color: ${props => (props.bgColor === 'dark' ? '#000' : '#FFF')};
  ${props => props.theme.breakpoints.down('sm')} {
    margin: ${props => props.theme.spacing(0.5)};
    min-width: ${props => (props.fullWidth ? '100%' : '240px')};
  }
`;

export const CustomPlanContainer = styled(Paper)<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: ${props => props.theme.spacing(2)};
  ${props => props.theme.breakpoints.down('sm')} {
    margin: ${props => props.theme.spacing(0.5)};
    min-width: ${props => (props.fullWidth ? '100%' : '240px')};
  }
`;
