export const formatCNPJ = (value: number | string): string => {
  const doc = typeof value === 'number' ? value.toString() : value;
  if (doc.length === 14) {
    return `${doc.slice(0, 2)}.${doc.slice(2, 5)}.${doc.slice(5, 8)}/${doc.slice(
      8,
      12,
    )}-${doc.slice(12, 14)}`;
  }
  return doc;
};
