import styled from '@emotion/styled';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { ProductCoffeeKind } from 'core/lib/graphql/types.d';

export type MenuPlanItem = {
  kind: ProductCoffeeKind;
  icon: any;
};

const NavIcon = styled(OptimizedImage)`
  touch-action: none;
`;

const imgBeans = (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.014" height="24.83" viewBox="0 0 31.014 24.83">
    <g id="layer1" transform="translate(-30.289 -47.139)">
      <path
        id="path1085"
        d="M47.678,71.9a7.464,7.464,0,0,1-5.392-3.121,7.865,7.865,0,0,1-.423-.722c-.168-.333-.212-.38-.327-.348-.073.02-.412.068-.753.107a7.608,7.608,0,0,1-4.46-1.052,12.9,12.9,0,0,1-5.986-9.929c-.315-3.318.893-6.657,2.988-8.262a7.027,7.027,0,0,1,3.255-1.382,8.4,8.4,0,0,1,6.72,2.581A11.339,11.339,0,0,1,45.07,51.9a14,14,0,0,1,1.706,3.74,2.193,2.193,0,0,0,.174.514c2.768-1.424,4.311-1.863,6.544-1.862,3.617,0,6.449,1.777,7.436,4.662a7.325,7.325,0,0,1,.014,4.567,12.137,12.137,0,0,1-1.179,2.542,13.488,13.488,0,0,1-8.816,5.754,13.279,13.279,0,0,1-3.273.087Zm3.308-1.939A11.718,11.718,0,0,0,56.731,66.9a8.2,8.2,0,0,0,2.746-6,4.448,4.448,0,0,0-.337-1.537,16.43,16.43,0,0,0-2.88.452,6.434,6.434,0,0,0-3.389,2.323,11.318,11.318,0,0,0-.8,1.38,8.243,8.243,0,0,1-2.875,3.509,8.571,8.571,0,0,1-3.886,1.463l-.8.139.36.273a6.3,6.3,0,0,0,3.057,1.2,11.971,11.971,0,0,0,3.065-.146ZM44.757,66.75a7.3,7.3,0,0,0,3.5-1.274,7.564,7.564,0,0,0,1.456-1.454,15.143,15.143,0,0,0,.78-1.394,8.358,8.358,0,0,1,1.834-2.576,8.169,8.169,0,0,1,4.644-2.231,5.612,5.612,0,0,0,.955-.206,4.286,4.286,0,0,0-1.091-.765,6.759,6.759,0,0,0-2.082-.656,11.147,11.147,0,0,0-3.222.1c-4.7,1-8.516,4.95-8.516,8.8,0,.816.246,1.869.436,1.868C43.491,66.961,44.08,66.866,44.757,66.75ZM41.1,65.959c.117-.034.121-.083.113-1.562a6.878,6.878,0,0,0-.467-3.161,6.637,6.637,0,0,0-2.617-3.094,8.428,8.428,0,0,1-3.213-4.365,8.531,8.531,0,0,1-.372-2.62,8.331,8.331,0,0,0-.079-1.167,7.677,7.677,0,0,0-1.086,1.17,9.227,9.227,0,0,0-1.04,6.859,13.25,13.25,0,0,0,1.9,4.415A8.276,8.276,0,0,0,39.465,66a7.8,7.8,0,0,0,1.637-.037Zm2.537-6.981c.258-.307.739-.812,1.07-1.121l.6-.563-.117-.529a12.834,12.834,0,0,0-1.67-3.926c-1.705-2.534-4.093-3.977-6.389-3.862-.521.026-.648.051-.717.144a16.965,16.965,0,0,0,.07,3.542,6.643,6.643,0,0,0,2.537,3.885A8.784,8.784,0,0,1,41.373,58.7a9.916,9.916,0,0,1,.791,1.265l.311.626.348-.53C43.014,59.774,43.381,59.285,43.639,58.978Z"
        transform="translate(0)"
        fill="#666"
      />
    </g>
  </svg>
);

const imgGround = (
  <svg xmlns="http://www.w3.org/2000/svg" width="33.655" height="24.83" viewBox="0 0 33.655 24.83">
    <g id="layer1" transform="translate(-15.561 -46.391)">
      <path
        id="path902"
        d="M7.071,58.085a1.069,1.069,0,0,1-.51-.851,1.163,1.163,0,0,1,.13-.446c.072-.134,2.742-3.5,5.934-7.475,6.2-7.724,6.163-7.682,7.275-8.336a6.949,6.949,0,0,1,1.96-.786,8.4,8.4,0,0,1,2.95-.035,7.316,7.316,0,0,1,3.173,1.607c.275.241,2.408,2.85,6.227,7.616,3.2,3.987,5.856,7.34,5.913,7.45a.97.97,0,0,1-.415,1.256l-.265.135-16.063,0c-15.863,0-16.067,0-16.309-.133Zm30.066-1.873c0-.043-9.711-12.172-10.053-12.556a5.186,5.186,0,0,0-2.441-1.521,6.307,6.307,0,0,0-2.5.007,4.955,4.955,0,0,0-2.178,1.242c-.292.29-10.315,12.755-10.315,12.828,0,.02,6.184.036,13.743.036S37.137,56.232,37.137,56.212ZM17.044,52.1a1.047,1.047,0,0,1-.554-.854,1.084,1.084,0,0,1,.986-.986,1.1,1.1,0,0,1,.986.986,1.069,1.069,0,0,1-.551.851.808.808,0,0,1-.868,0Zm3.945,0a1.047,1.047,0,0,1-.554-.854,1.084,1.084,0,0,1,.986-.986,1.1,1.1,0,0,1,.986.986,1.069,1.069,0,0,1-.551.851.808.808,0,0,1-.868,0Zm0-3.945a1.047,1.047,0,0,1-.554-.854,1.084,1.084,0,0,1,.986-.986,1.1,1.1,0,0,1,.986.986,1.069,1.069,0,0,1-.551.851.808.808,0,0,1-.868,0Zm.973-9.645a1.047,1.047,0,0,1-.554-.854,1.084,1.084,0,0,1,.986-.986,1.1,1.1,0,0,1,.986.986,1.069,1.069,0,0,1-.551.851A.808.808,0,0,1,21.962,38.514Zm1.981-3.29a1.047,1.047,0,0,1-.554-.854,1.1,1.1,0,0,1,.526-.847.867.867,0,0,1,.928,0,1.144,1.144,0,0,1,.519.843,1.069,1.069,0,0,1-.551.851.808.808,0,0,1-.868,0Z"
        transform="translate(9 13)"
        fill="#666"
      />
    </g>
  </svg>
);

const imgCapsule = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.915"
    height="24.832"
    viewBox="0 0 35.915 24.832"
  >
    <g id="layer1" transform="translate(-38.93 -86.969)">
      <path
        id="path854"
        d="M43.911,110.73l-2.069-1.068-.714-4.595c-.393-2.527-.716-4.6-.719-4.612s-.337-.018-.742-.018H38.93v-2.1h11.9l-.709-4.612-.709-4.612-.75-.02-.75-.02v-2.1H65.867v2.1l-.75.02-.75.02-.709,4.612-.709,4.612h11.9v2.1h-.732c-.637,0-.736.016-.767.123-.019.068-.341,2.127-.714,4.577s-.715,4.49-.759,4.534-.982.541-2.085,1.1L67.783,111.8l-1.923,0-1.922,0-2.069-1.068L59.8,109.662l-.714-4.595c-.393-2.527-.716-4.6-.719-4.612s-.666-.018-1.474-.018c-1.338,0-1.471.011-1.5.123-.019.068-.341,2.127-.714,4.577s-.715,4.49-.759,4.534-.982.541-2.085,1.1L49.825,111.8l-1.922,0-1.922,0Zm6.75-1.713a5.965,5.965,0,0,0,1.392-.824c.056-.136,1.188-7.423,1.188-7.649,0-.094-.624-.106-5.331-.106-5.03,0-5.331.007-5.331.123,0,.238,1.131,7.56,1.183,7.658a11.02,11.02,0,0,0,1.382.788l1.328.688h2.857Zm17.958,0a5.965,5.965,0,0,0,1.392-.824c.056-.136,1.188-7.423,1.188-7.649,0-.094-.624-.106-5.331-.106-5.03,0-5.331.007-5.331.123,0,.238,1.131,7.56,1.183,7.658a11.019,11.019,0,0,0,1.382.788l1.328.688h2.857ZM59.657,97.626c1.035-.534,1.379-.745,1.405-.859.054-.235,1.157-7.466,1.157-7.586,0-.094-.611-.106-5.331-.106s-5.331.012-5.331.106c0,.194,1.117,7.431,1.173,7.6.041.123.385.334,1.387.852l1.333.688,1.418.008,1.418.008Z"
        fill="#666"
      />
    </g>
  </svg>
);

export const plansMenu: MenuPlanItem[] = [
  {
    kind: ProductCoffeeKind.Beans,
    icon: <NavIcon src={imgBeans} ext="svg" include />,
  },
  {
    kind: ProductCoffeeKind.Ground,
    icon: <NavIcon src={imgGround} ext="svg" include />,
  },
  {
    kind: ProductCoffeeKind.Capsule,
    icon: <NavIcon src={imgCapsule} ext="svg" include />,
  },
];
