export const formatPostalCode = (postalCode: string | null) => {
  const newPostalCode = (postalCode || '').replace(/[^0-9]/g, '').slice(0, 8);

  if (newPostalCode.length === 0) {
    return '';
  }

  const after = newPostalCode.slice(5, 8);
  return `${newPostalCode.slice(0, 5)}${after.length > 0 ? `-${after}` : ''}`;
};
