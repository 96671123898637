import { MouseEvent, PropsWithChildren, ReactNode, useMemo } from 'react';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import { SmartTab } from './smart-tab';

export type SmartTabOption<T> = {
  label: string;
  badgeContent?: ReactNode;
  value: T;
};
export interface SmartTabsProps<T> {
  tabs: SmartTabOption<T>[];
  currentTab: T;
  customBorderRadius?: number;
  className?: string;
  onClick: (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    value: T,
  ) => Promise<void> | void;
}

const Wrapper = styled(Paper)`
  display: flex;
  & > button:not(:last-child) {
    margin-right: ${props => props.theme.spacing(1)};
  }
  padding: ${props => props.theme.spacing(1)};
`;

export const SmartTabs = <T extends string | number>({
  tabs,
  currentTab,
  className,
  customBorderRadius,
  children,
  onClick,
}: PropsWithChildren<SmartTabsProps<T>>) => {
  const ElTabs = useMemo(
    () =>
      tabs.map(({ value, label, badgeContent }) => (
        <SmartTab
          key={value}
          customBorderRadius={customBorderRadius}
          badgeContent={badgeContent}
          active={value === currentTab}
          onClick={e => onClick(e, value)}
        >
          {label}
        </SmartTab>
      )),
    [tabs, customBorderRadius, currentTab, onClick],
  );
  return (
    <>
      <Wrapper className={className}>{ElTabs}</Wrapper>
      {children}
    </>
  );
};
