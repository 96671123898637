import styled from '@emotion/styled';
import { Box, ButtonBase, Typography } from '@mui/material';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { CustomPlanContainer } from '@web/common/styles/plans';
import { SubscriptionKind } from 'core/lib/graphql/types.d';
import NextLink from 'next/link';
import { FC, MouseEvent } from 'react';
import { subscriptionPlanLabelDict } from './plans-type';

interface CustomPlanProps {
  planId: string;
  onClick?: (e: MouseEvent) => void;
  planKind: SubscriptionKind;
}

const StyledButton = styled(ButtonBase)`
  margin: ${props => props.theme.spacing(1)};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  min-height: 400px;
`;

export const CustomPlan: FC<CustomPlanProps> = ({ planId, onClick, planKind }) => (
  <NextLink href={`/loja/carrinho-assinatura/${planId}`} passHref>
    <StyledButton onClick={onClick} data-e2e="plans-section-custom-plan">
      <CustomPlanContainer>
        <OptimizedImage src="/icons/plan-magic-wand.svg" ext="svg" />
        <Box my={1}>
          <Typography variant="h5" component="h3" color="primary" align="center">
            Personalizar seu plano {subscriptionPlanLabelDict[planKind]}
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center">
            Crie um plano customizado para você!
          </Typography>
        </Box>
      </CustomPlanContainer>
    </StyledButton>
  </NextLink>
);
