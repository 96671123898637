import styled from '@emotion/styled';
import { Typography, Box, Container } from '@mui/material';
import { FC } from 'react';
import { MenuPlans, MenuPlansProps } from './menu-plans';
import { PlansKind, PlansKindProps } from './plans-kind';
import { PlansSection, PlansSectionProps } from './plans-section';

const MediumScreenUpWrapper = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

type PlansSelectorProps = MenuPlansProps &
  PlansSectionProps &
  PlansKindProps & {
    id?: string;
    className?: string;
    bottomMessage?: string;
  };

export const PlansSelector: FC<PlansSelectorProps> = ({
  id,
  className,
  selectedPlansOptions,
  selectedKind,
  bottomMessage = 'Arraste para o lado para ver mais',
  plansOnChange,
  onPlanKindChange,
  currentPlanKind,
  supportedPlans,
  ...rest
}) => (
  <div className={className}>
    <Box my={2} id={id}>
      <MenuPlans
        selectedPlansOptions={selectedPlansOptions}
        selectedKind={selectedKind}
        plansOnChange={plansOnChange}
      />
    </Box>
    <Container maxWidth="sm" sx={{ marginBottom: 3 }}>
      <PlansKind
        supportedPlans={supportedPlans}
        currentPlanKind={currentPlanKind}
        onPlanKindChange={onPlanKindChange}
      />
    </Container>
    <Typography variant="subtitle2" color="textSecondary" align="center" sx={{ marginBottom: 1 }}>
      Selecione a quantidade desejada
    </Typography>
    <PlansSection currentPlanKind={currentPlanKind} {...rest} />
    <MediumScreenUpWrapper>
      <Typography variant="body2" color="textSecondary" align="center">
        {bottomMessage}
      </Typography>
    </MediumScreenUpWrapper>
  </div>
);
