import { ProductCoffeeKind } from 'core/lib/graphql/types.d';
import { SubscriptionPlanFieldsFragment } from 'core/lib/graphql/_gen/plans';
import { useMemo } from 'react';
import { useSupportedPlans } from './use-supported-plans';

export const useSupportedPlansWithDiscount = (
  plans: SubscriptionPlanFieldsFragment[],
  currentCoffeeKind: ProductCoffeeKind,
) => {
  const { supportedPlans } = useSupportedPlans(plans, currentCoffeeKind);
  const supportedPlansWithDiscount = useMemo(
    () =>
      supportedPlans.map(planKind => {
        const maximumDiscount = Math.max(
          ...plans.filter(plan => plan.kind === planKind).map(p => p?.discountPercent || 0),
        );
        return {
          kind: planKind,
          discount: maximumDiscount,
        };
      }),
    [plans, supportedPlans],
  );
  return { supportedPlansWithDiscount };
};
