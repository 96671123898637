import { FC, forwardRef } from 'react';
import styled from '@emotion/styled';

export type BreakPoint = 'sm' | 'md' | 'lg' | 'xl';

interface GridFlatListProps {
  /** When the screen is below this breakpoint it stops being a grid and starts being
   *  a horizontally scroll container  */
  className?: string;
  horizontalBreakpoint?: BreakPoint;
  // eslint-disable-next-line react/no-unused-prop-types
  ref?: any;
}

const Grid = styled.div<{ horizontalBreakpoint?: BreakPoint }>`
  display: flex;
  justify-items: stretch;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing(0, -1)};

  ${props => props.theme.breakpoints.down('md')} {
    margin: ${props => props.theme.spacing(0, -0.5)};
  }

  ${props => props.theme.breakpoints.down(props.horizontalBreakpoint ?? 0)} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: ${props => props.theme.spacing(1.5)};
    ::-webkit-scrollbar {
      display: none;
    }

    ${props => props.theme.breakpoints.down('md')} {
      padding: ${props => props.theme.spacing(0, 1.5, 1.5, 1.5)};
      margin: ${props => props.theme.spacing(0, -2)};
    }
  }
`;

/** A grid that also works as a horizontally scroll container bellow a certain breakpoint */
export const GridFlatList: FC<GridFlatListProps> = forwardRef<HTMLDivElement, GridFlatListProps>(
  ({ horizontalBreakpoint, className, children }, ref) => (
    <Grid className={className} horizontalBreakpoint={horizontalBreakpoint} ref={ref}>
      {children}
    </Grid>
  ),
);
