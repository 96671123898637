import { FC, MouseEvent } from 'react';
import { ButtonNavigationItem } from '@web/common/components/navigation/button-navigation-item';
import { ProductCoffeeKind } from 'core/lib/graphql/types.d';
import { Box, Grid, Typography } from '@mui/material';
import { MenuPlanItem, plansMenu } from '@web/common/constants/clube-veroo/menu-plans';

const plansByKind: Record<ProductCoffeeKind, string> = {
  [ProductCoffeeKind.Capsule]: 'Cápsulas',
  [ProductCoffeeKind.Beans]: 'Grãos',
  [ProductCoffeeKind.Ground]: 'Moído',
};

export interface MenuPlansProps {
  selectedPlansOptions?: MenuPlanItem[];
  selectedKind: ProductCoffeeKind;
  plansOnChange: (e: MouseEvent<Element, globalThis.MouseEvent>, kind: ProductCoffeeKind) => void;
}

export const MenuPlans: FC<MenuPlansProps> = ({
  selectedPlansOptions = plansMenu,
  selectedKind,
  plansOnChange,
}) => (
  <Box margin="auto" maxWidth="550px" sx={{ marginBottom: 3 }}>
    <Typography align="center" variant="subtitle2" color="textSecondary" sx={{ marginBottom: 1 }}>
      Selecione o tipo de café
    </Typography>
    <Grid container spacing={1}>
      {selectedPlansOptions.map(it => (
        <Grid item xs={4} key={it.kind}>
          <ButtonNavigationItem
            label={plansByKind[it.kind]}
            icon={it.icon}
            onClick={e => plansOnChange(e, it.kind)}
            active={it.kind === selectedKind}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
);
